import * as Logger from './logger'
import * as Payments from './payments'
import * as React from 'react'
import * as ReactRedux from 'react-redux'

function getTransactionData(state) {
  return {
    error: Payments.getTransactionError(state.payments),
    id: Payments.getTransactionId(state.payments),
    state: Payments.getTransactionState(state.payments),
  }
}

export function useTransactionLogging({
  paymentMethod,
  step,
  transactionType,
  userId,
}) {
  const transaction = ReactRedux.useSelector(getTransactionData)

  // Log flow interruptians via page visibility change
  React.useEffect(() => {
    function handleVisibilityChange() {
      if (document.visibilityState === 'visible') {
        Logger.react.info('page visible')
      }

      if (document.visibilityState === 'hidden') {
        Logger.react.info('page hidden')
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  // Log transaction flow initialisation via transaction type change
  React.useEffect(() => {
    function handlePageHide() {
      Logger.react.info(
        {transactionType, userAgent: navigator.userAgent, userId},
        'deinitialized transaction'
      )
    }

    Logger.react.info(
      {transactionType, userAgent: navigator.userAgent, userId},
      'initialized transaction'
    )

    // Dispatched when user navigates away (back/forward/close) from page
    window.addEventListener('pagehide', handlePageHide, false)

    return () => {
      window.removeEventListener('pagehide', handlePageHide)
    }
  }, [transactionType, userId])

  // Log step changes
  React.useEffect(() => {
    Logger.react.info({step}, 'updated step')
  }, [step])

  // Log transaction ID
  React.useEffect(() => {
    if (transaction.id) {
      const startedAt = Date.now()
      Logger.react.info(
        {paymentMethod, transactionId: transaction.id},
        'started transaction'
      )
      return () => {
        const endedAt = Date.now()
        Logger.react.info(
          {
            durationSeconds: Math.round((endedAt - startedAt) / 1000),
            paymentMethod,
            transactionId: transaction.id,
          },
          'finished transaction'
        )
      }
    }
  }, [paymentMethod, transaction.id])

  // Log transaction state changes
  React.useEffect(() => {
    if (transaction.state) {
      Logger.react.info(
        {transactionState: transaction.state},
        'updated transaction state'
      )
    }
  }, [transaction.state])

  // Log transaction errors
  React.useEffect(() => {
    if (transaction.error) {
      Logger.react.error(transaction.error)
    }
  }, [transaction.error])
}
