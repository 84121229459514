import * as Herz from '@rushplay/herz'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Native checkbox - Form control
 * @component Checkbox
 * @param {Object} props Components props
 * @param {boolean} props.checked Updates visual state of component
 * @param {boolean=} props.disabled if form-control is enabled or not
 * @param {string} props.label translation-key to use as label
 * @param {string} props.name Name of form-control
 * @param {boolean} props.value Value of component
 * @param {func} props.onChange function to trigger when value changes
 * @returns {ReactNode} Checkbox form-control
 */
export function Checkbox(props) {
  const translate = Herz.I18n.useTranslate(() => [props.label])

  return (
    <ThemeUi.Label variant="checkboxLabel">
      <ThemeUi.Checkbox
        name={props.name}
        disabled={props.disabled}
        checked={props.checked}
        value={props.value}
        onChange={props.onChange}
      />
      {translate(props.label)}
    </ThemeUi.Label>
  )
}

Checkbox.propTypes = {
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
}
