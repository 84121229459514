export class BadRequestError extends Error {
  constructor(message) {
    super(message)
    this.name = 'BadRequestError'
    this.statusCode = 400
  }
}

export class NotFoundError extends Error {
  constructor(message) {
    super(message)
    this.name = 'NotFoundError'
    this.statusCode = 404
  }
}

export class MissingQueryParamsError extends BadRequestError {
  constructor(missingQueryParams) {
    const formattedQueryParams = missingQueryParams
      .map((queryParam) => `"${queryParam}"`)
      .join(', ')
    super(`Required data is missing: ${formattedQueryParams}`)
    this.missingQueryParams = missingQueryParams
    this.name = 'MissingQueryParamsError'
  }
}

export class UnsupportedTransactionError extends NotFoundError {
  constructor() {
    super('The transaction type is not supported')
    this.name = 'UnsupportedTransactionError'
  }
}
