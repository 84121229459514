import * as Configuration from '../configuration'
import * as Forms from '@rushplay/forms'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import {fetch} from '../fetch'

async function fetchBanks(apiUrl, merchantId, countryCode) {
  const url = `${apiUrl}/api/directa24/getBanksByCountry`

  const response = await fetch(url, {
    headers: {
      'Accept': 'application/vnd.casinosaga.v1',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({merchantId: merchantId, countryCode: countryCode}),
    method: 'POST',
    mode: 'cors',
  })

  const body = await response.json()
  return body
}

/**
 * Component that renders all available payment methods for Directa24
 * @component Directa24Banks
 * @returns {ReactNode}
 */
export function Directa24PaymentMethods() {
  const [bankList, setBankList] = React.useState([])
  const [service, setService] = React.useState(null)
  const directa24Service = Forms.useField(
    '#/definitions/PiqBankDirecta24Deposit/properties/service'
  )
  const countryCode = ReactRedux.useSelector(Configuration.getCountryCode)
  const piqConfig = ReactRedux.useSelector(Configuration.getPiqConfig)
  const merchantId = piqConfig.merchantId
  const apiUrl = piqConfig.apiUrl

  const handleChange = React.useCallback(
    (service) => {
      setService(service)
      directa24Service.onChangeValue(service)
    },
    [directa24Service.onChangeValue]
  )

  React.useEffect(() => {
    fetchBanks(apiUrl, merchantId, countryCode).then((res) => setBankList(res))
  }, [apiUrl, merchantId, countryCode])

  if (bankList.length === 0) {
    return null
  }

  return (
    <ThemeUi.Grid
      sx={{
        gap: [0, 1],
        gridTemplateColumns: 'repeat(auto-fit, minmax(70px, 0.5fr))',
      }}
    >
      {bankList.map((bank) => (
        <ThemeUi.Box
          display="flex"
          as="label"
          key={bank.code}
          sx={{
            'border': '1px solid',
            'borderColor': 'muted',
            'textAlign': 'center',
            'alignItems': 'center',
            'justifyContent': 'center',
            'borderRadius': '8px',
            'px': 1,
            'py': 0,
            'height': '100%',
            'width': '100%',
            'cursor': 'pointer',
            '&:hover': {
              borderColor: 'primary',
            },
            '&:active': {
              transform: 'scale(0.975)',
            },
            '&:focus-within': {
              border: '2px solid',
              borderColor: 'success',
            },
          }}
        >
          <ThemeUi.Box
            as="input"
            sx={{
              opacity: 0,
              zIndex: '-1',
              overflow: 'hidden',
              position: 'absolute',
            }}
            checked={service === bank.code}
            name="DIRECTA24-PiqBankDirecta24Deposit"
            type="radio"
            value={bank.code}
            onChange={() => handleChange(bank.code)}
          />
          <ThemeUi.Image
            alt={bank.name}
            src={bank.logo}
            sx={{
              height: '50px',
              width: '50px',
            }}
          />
          <ThemeUi.Box
            sx={{
              fontSize: 0,
            }}
          >
            {bank.name}
          </ThemeUi.Box>
        </ThemeUi.Box>
      ))}
    </ThemeUi.Grid>
  )
}
