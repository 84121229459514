import * as Forms from '@rushplay/forms'
import * as React from 'react'

import {Checkbox} from './checkbox'
import PropTypes from 'prop-types'

/**
 * Component that extends native Checkbox with field-updating capabilities
 * @component CheckboxField
 * @param {Object} props Components props
 * @param {boolean=} props.disabled if form-control is enabled or not
 * @param {string=} props.label label to use for the checkbox
 * @param {string} props.scope scope inside dataschema of parent Form
 * @returns {ReactNode} Checkbox that updates Form state via Field
 */
export function CheckboxField(props) {
  const field = Forms.useField(props.scope)

  return (
    <Checkbox
      checked={field.value}
      disabled={props.disabled}
      label={props.label ? props.label : field.label}
      name={field.name}
      value={field.value}
      onChange={() => field.onChangeValue(!field.value)}
    />
  )
}

CheckboxField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  scope: PropTypes.string.isRequired,
}
