import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Forms from '@rushplay/forms'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'
import URL from 'url'

/**
 * Component to handle transactions via Pwclick
 * @component PwclickProcessing
 * @returns {ReactNode} Iframe
 */
export function PwclickProcessing(props) {
  const amountCents = Forms.useField('#/properties/amount', {noRegister: true})
    .value
  const origin = ReactRedux.useSelector(Configuration.getOrigin)
  const pwclickConfig = ReactRedux.useSelector((state) =>
    Configuration.getPwclickConfig(state)
  )

  const urlQuery = {
    amount: amountCents / 100,
    countryCodes: ReactRedux.useSelector(Configuration.getCountryCode),
    currency: ReactRedux.useSelector(Configuration.getCurrency),
    reference: ReactRedux.useSelector(Configuration.getUserId),
    successUrl: `${origin}/callbacks/pwclick?state=success`,
    failureUrl: `${origin}/callbacks/pwclick?state=failure`,
  }

  const parsedUrl = URL.parse(pwclickConfig.iframeUrl)
  const url = URL.format({
    host: parsedUrl.host,
    pathname: `${parsedUrl.path}${pwclickConfig.appId}`,
    protocol: parsedUrl.protocol,
    query: urlQuery,
  })

  React.useEffect(() => {
    function messageReceive(event) {
      if (event.key == 'pwclickMessage') {
        const message = JSON.parse(event.newValue)

        switch (message.state) {
          case 'failure': {
            props.onStepChange(Constants.TransactionStep.Failure)

            break
          }

          case 'success': {
            props.onStepChange(Constants.TransactionStep.Success)

            break
          }
        }

        localStorage.removeItem('pwclickMessage')
      }
    }

    window.addEventListener('storage', messageReceive)

    return () => window.removeEventListener('storage', messageReceive)
  })

  return (
    <ThemeUi.Flex
      sx={{
        'alignItems': 'center',
        'justifyContent': 'center',
        'flexDirection': 'column',
        'height': '100%',
        '#brite-payment-system': {
          height: '100%',
          width: '100%',
          maxWidth: '600px',
        },
      }}
    >
      <iframe
        src={url}
        sandbox="allow-forms allow-modals allow-orientation-lock allow-popups allow-popups-to-escape-sandbox allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation"
        style={{border: 'none'}}
      />
    </ThemeUi.Flex>
  )
}

PwclickProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
