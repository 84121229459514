import * as React from 'react'

import {SelectField} from './select-field'
import {usePaymentMethod} from '../payments/components/payment-method/use-payment-method'

export function PayustSelector() {
  const paymnetMethod = usePaymentMethod('PayustPayustDeposit')

  const methodsList = paymnetMethod?.subPaymentMethods || []
  const options = methodsList.map((method) => ({
    label: `transaction.payment-method.${method}`,
    value: method,
  }))

  return (
    <>
      <SelectField
        autoFocus
        options={options}
        initialValue={options[0]?.value}
        scope={`#/definitions/PayustPayustDeposit/properties/paymentMethod`}
      />
    </>
  )
}
