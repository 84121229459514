import * as React from 'react'
import * as ThemeUI from 'theme-ui'

import PropTypes from 'prop-types'

export function HtmlContent(props) {
  return (
    <ThemeUI.Box
      dangerouslySetInnerHTML={{__html: props.children}}
      as={props.as}
      sx={{
        'color': 'text',
        'fontFamily': 'body',

        'h1, h2, h3, h4': {
          fontFamily: 'heading',
        },

        'table': {
          display: 'grid',
          overflowX: 'auto',
          maxWidth: '100%',
        },

        'a': {
          'color': 'link',
          'fontWeight': 'bold',
          'textDecoration': 'underline',
          'transition': 'color 100ms ease',

          '&:hover': {
            color: 'secondary',
          },
        },
        ...props.sx,
      }}
    />
  )
}

HtmlContent.propTypes = {
  children: PropTypes.string.isRequired,
  sx: PropTypes.object,
  as: PropTypes.string,
}
