import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

/**
 * Component used for FieldBuilder, to be able to generate custom UI-schemas
 * To be used for pure information with no values gathering.
 * @component VegaWalletLink
 * @param {Object} props Components props
 * @param {*=} props.* Any valid style-in-js prop @see {@link https://theme-ui.com/guides/object-styles}
 * @returns {ReactNode} Component with Vega terms link
 */
export function VegaWalletLink(props) {
  return (
    <ThemeUi.Text
      sx={{
        ...R.omit(['text'], props),
        fontSize: '14px',
        px: '4px',
      }}
    >
      <ThemeUi.Link
        href="https://vega-wallet.com/whitelist/"
        target="_blank"
        rel="noreferrer"
      >
        使い方（必読)
      </ThemeUi.Link>
    </ThemeUi.Text>
  )
}
