import * as Constants from './constants'
import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as Payments from './payments'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Suspense from './suspense'
import * as ThemeUi from 'theme-ui'

import {Image} from './image'
import PropTypes from 'prop-types'
import Url from 'url'
import kebabCase from 'lodash.kebabcase'

function MenuPaymentProviderImage() {
  const provider = Forms.useField('#/properties/provider', {noRegister: true})
    .value
  const imageTranslationKey = `payment-method.${kebabCase(provider)}`

  const translate = Herz.I18n.useTranslate(() => [imageTranslationKey], [
    provider,
  ])
  const imageSource = Url.parse(translate(imageTranslationKey) || '')

  return (
    <Image
      alt={provider}
      source={imageSource.pathname || ''}
      resizingHeight={24}
      sx={{
        maxHeight: '24px',
        fontSize: 0,
      }}
    />
  )
}

/**
 * Menu for with basic user api such as close/back & ui-sugar
 * @component Menu
 * @param {Object} props Components props
 * @param {number} props.step Current step
 * @param {func} props.onStepChange Callback to change payment step
 * @returns {ReactNode}
 */
export function Menu(props) {
  const translate = Herz.I18n.useTranslate()
  const {transactionType} = Payments.useTransactionContext()
  const {paymentMethods, accounts} = ReactRedux.useSelector((state) =>
    Payments.getSortedPaymentMethods(state, transactionType)
  )

  const skipMethodStep = paymentMethods.length === 1 && R.isEmpty(accounts)

  const hasBack =
    !skipMethodStep &&
    R.contains(props.step, [
      Constants.TransactionStep.PaymentDetails,
      Constants.TransactionStep.Processing,
    ])

  if (
    R.contains(props.step, [
      Constants.TransactionStep.Cancel,
      Constants.TransactionStep.Failure,
      Constants.TransactionStep.Success,
    ])
  ) {
    return null
  }

  return (
    <ThemeUi.Grid
      columns="30px 1fr 30px"
      sx={{
        color: 'formLabel',
        fontSize: 4,
        py: 0,
        mb: 1,
        borderBottom: '1px solid',
        borderColor: 'muted',
        position: 'sticky',
        bg: 'navBackground',
        top: '0px',
        left: '0px',
        right: '0px',
        zIndex: '999',
      }}
    >
      {hasBack && (
        <ThemeUi.Flex
          sx={{
            'cursor': 'pointer',
            'gridColumn': 1,
            'alignItems': 'center',
            '&:hover': {
              color: 'text',
            },
          }}
          onClick={() =>
            props.onStepChange(Constants.TransactionStep.PaymentMethod)
          }
        >
          <Icons.NavigateBack />
        </ThemeUi.Flex>
      )}
      <ThemeUi.Flex
        sx={{
          alignItems: 'center',
          justifyContent: 'center',
          color: 'text',
          gridColumn: 2,
        }}
      >
        {props.step === Constants.TransactionStep.PaymentMethod && (
          <ThemeUi.Heading as="h1" variant="title">
            {translate('menu.payment-methods.title')}
          </ThemeUi.Heading>
        )}
        {hasBack && (
          <Suspense.Boundary fallback={Suspense.Fallbacks.Empty}>
            <MenuPaymentProviderImage />
          </Suspense.Boundary>
        )}
      </ThemeUi.Flex>
      <ThemeUi.Flex
        sx={{
          'cursor': 'pointer',
          'gridColumn': 3,
          'alignItems': 'center',
          '&:hover': {
            color: 'text',
          },
        }}
        onClick={() => props.onStepChange(Constants.TransactionStep.Cancel)}
      >
        <Icons.Close />
      </ThemeUi.Flex>
    </ThemeUi.Grid>
  )
}

Menu.propTypes = {
  step: PropTypes.number,
  onStepChange: PropTypes.func.isRequired,
}

Herz.I18n.Loader.preload(['menu.payment-methods.title'], Menu)
