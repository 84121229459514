import * as Forms from '@rushplay/forms'
import * as React from 'react'

import {InputField} from './input-field'
import PropTypes from 'prop-types'

/**
 * Component that extends InputField but forces inputMode
 * @component NumberInputField
 * @param {Object} props Components props
 * @param {string|number=} props.initialValue intial value of control
 * @param {string} props.scope scope inside dataschema of parent Form
 * @returns {ReactNode}
 */
export function NumberInputField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return <InputField inputMode="numeric" {...field} scope={props.scope} />
}

NumberInputField.propTypes = {
  initialValue: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
}
