import * as Data from './data'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

/**
 * Component to render when a transaction was successful
 * @component Success
 * @returns {ReactNode}
 */
export function Success() {
  const dispatch = ReactRedux.useDispatch()
  const forwardData = ReactRedux.useSelector(Data.getForwardData)
  const container = ReactRedux.useSelector((state) =>
    R.path(['activeTransaction', 'redirectOutput', 'container'], state.payments)
  )

  React.useEffect(() => {
    dispatch(Data.transactionSuccess({container, ...forwardData}))
  }, [container, dispatch, forwardData])

  return (
    <ThemeUi.Box
      sx={{
        display: 'flex',
        flexGrow: '1',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '120px',
        color: 'success',
      }}
    >
      <Icons.Success />
    </ThemeUi.Box>
  )
}
