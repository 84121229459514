import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {SelectField} from './select-field'
import {useFetchData} from '../use-fetch-data'

/**
 * Component that renders a SelectField with available bank codes for Paymero service options.
 * The codes are fetched from the Paymero API to reduce transaction failure in case a wallet provider will not be available.
 * If no codes are returned then this service will be unavilable and the user should select another method instead.
 * @component PaymeroWithdrawalsCodeSelect
 * @returns {ReactNode}
 */
export function PaymeroWithdrawalsCodeSelect() {
  const [banks, loading] = useFetchData(
    '/api/paymero/banks?service=bank-transfer&method=payout'
  )

  const selectCodes = React.useMemo(() => {
    if (loading) {
      return []
    }
    return banks?.map((curr) => ({value: curr.code, label: curr.name}))
  }, [banks, loading])

  if (!loading && banks?.length === 0) {
    // TODO Errors
    return null
  }

  if (loading) {
    return (
      <ThemeUi.Box
        sx={{
          p: 2,
          fontSize: 5,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <ThemeUi.Spinner />
      </ThemeUi.Box>
    )
  }

  return (
    <SelectField
      autoFocus
      options={selectCodes}
      initialValue={selectCodes[0].value}
      scope="#/definitions/paymeroWithdrawalsCodeSelect/properties/bankName"
    />
  )
}
