import * as React from 'react'

async function fetchData(url, options) {
  const response = await fetch(url, options)

  return await response.json()
}

export function useFetchData(url, options) {
  const [response, setResponse] = React.useState([])
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    setLoading(true)

    fetchData(url, options)
      .then((res) => {
        setResponse(res)
        setLoading(false)
      })
      .catch(() => {
        setLoading(false)
      })
  }, [url, options])

  return [response, loading]
}
