/**
 * This is a simple implementation of Luhn algorithm
 * @param {string} cardNumber Card number to validate
 * @returns {boolean} If card number is valid
 * */
export function validateCardNumber(cardNumber = '') {
  if (!cardNumber.length) {
    return false
  }

  const numberArray = cardNumber
    .replaceAll(' ', '')
    .split('')
    .reverse()
    .map((item) => parseInt(item))
  const checkSum = numberArray.reduce((acc, current, index) => {
    if (index % 2 === 0) {
      return acc + current
    }

    const doubled = current * 2

    if (doubled > 9) {
      return acc + (doubled - 9)
    }

    return acc + doubled
  }, 0)

  return Boolean(checkSum % 10 === 0)
}
