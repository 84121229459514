import * as Configuration from './configuration'
import * as Herz from '@rushplay/herz'
import * as Hooks from './hooks'
import * as Icons from './icons'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import {Image} from './image'
import PropTypes from 'prop-types'
import Url from 'url'
import kebabCase from 'lodash.kebabcase'

/**
 * Visual component for single payment method or payment method account
 * @component PaymentMethod
 * @param {Object} props Components props
 * @param {number=} props.feePercent fee on transaction with this payment-method
 * @param {string} props.id payment-method id
 * @param {string=} props.lastSuccess last successful useage
 * @param {string=} props.maskedAccount Masked account user used
 * @param {number} props.maxCents Maxiumum allowed transaction amount
 * @param {number} props.minCents Minimum allowed transaction amount
 * @param {string} props.name Name of the form-field
 * @param {string=} props.selected Value of the selected form-field
 * @param {string} props.type payment-method type
 * @param {string} props.value Value of form-field
 * @param {func} props.onChange Callback to fire on onchange event
 * @returns {ReactNode} Iframe with brite loaded
 */
export function PaymentMethod(props) {
  const locale = ReactRedux.useSelector(Configuration.getLocale)
  const amountCents = ReactRedux.useSelector(Configuration.getAmountCents)
  const countryCode = ReactRedux.useSelector(Configuration.getCountryCode)
  const depositCount = ReactRedux.useSelector(Configuration.getDepositCount)

  const minAmount = Hooks.useToAmount({
    amountCents: props.minCents,
    minimumFractionDigits: '0',
  })
  const maxAmount = Hooks.useToAmount({
    amountCents: props.maxCents,
    minimumFractionDigits: '0',
  })
  const feeAmount = Hooks.useToAmount({
    amountCents: amountCents * (props.feePercent / 100),
    minimumFractionDigits: '0',
  })

  const disabled =
    R.lt(props.maxCents, amountCents) || R.gt(props.minCents, amountCents)

  const imageTranslationKey =
    depositCount == '0' &&
    countryCode === 'JP' &&
    props.id === 'PiqCreditcardDeposit'
      ? `payment-method.japan-credit-card-ftd`
      : `payment-method.${kebabCase(props.id)}`
  const requiredPSPInfo = `payment-method.${kebabCase(props.id)}.required-info`

  const translate = Herz.I18n.useTranslate(
    () => [imageTranslationKey, requiredPSPInfo],
    [props.id]
  )

  const imageSource = Url.parse(translate(imageTranslationKey) || '')

  return (
    <ThemeUi.Box
      as="label"
      sx={{
        'cursor': disabled ? 'not-allowed' : 'pointer',
        'borderRadius': 0,
        'border': '1px solid',
        'borderColor': 'muted',
        'bg': 'paymentMethodBackground',
        'color': 'muted',
        'fontStyle': 'italic',
        'fontSize': '8px',
        'p': 1,
        'overflow': 'hidden',
        'display': 'grid',
        'alignItems': 'flex-end',
        'gridGap': 0,
        'flexGrow': '1',
        'transition':
          'transform 125ms ease-in-out, border-color 125ms ease-in-out',
        '&:hover': {
          borderColor: !disabled && 'primary',
        },
        '&:active': {
          transform: !disabled && 'scale(0.975)',
        },
        '&:focus-within': {
          outlineColor: 'primary',
          outlineStyle: 'auto',
        },
      }}
    >
      <ThemeUi.Box
        as="input"
        sx={{
          opacity: 0,
          zIndex: '-1',
          overflow: 'hidden',
          position: 'absolute',
          width: '0',
          height: '0',
        }}
        checked={props.selected === props.value}
        disabled={disabled}
        name={props.name}
        type="radio"
        value={props.value}
        onChange={() => props.onChange(props.value)}
      />
      <ThemeUi.Box
        sx={{
          alignSelf: 'center',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          color: 'paymentMethodText',
          fontSize: 0,
          filter: disabled && 'grayscale(1)',
          height: '70px',
        }}
      >
        <Image
          alt={props.id}
          source={imageSource.pathname || ''}
          resizingHeight={70}
          sx={{
            maxHeight: '70px',
            maxWidth: '150px',
          }}
        />
      </ThemeUi.Box>

      {(props.maskedAccount || props.lastSuccess) && (
        <ThemeUi.Grid
          gap={0}
          sx={{color: 'paymentMethodText', fontSize: 0, fontStyle: 'normal'}}
        >
          {props.maskedAccount && (
            <ThemeUi.Text sx={{fontWeight: 'bold'}}>
              {props.maskedAccount}
            </ThemeUi.Text>
          )}
          {props.lastSuccess && (
            <ThemeUi.Text sx={{alignSelf: 'flex-end'}}>
              {translate('payment-method.last-used', {
                date: new Date(
                  R.replace(/-/g, '/', props.lastSuccess)
                ).toLocaleDateString(locale, {
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                }),
              })}
            </ThemeUi.Text>
          )}
        </ThemeUi.Grid>
      )}

      {translate(requiredPSPInfo)}

      {(props.feePercent > 0 ||
        R.gt(props.minCents, amountCents) ||
        R.lt(props.maxCents, amountCents)) && (
        <ThemeUi.Box
          sx={{
            bg: 'info',
            color: 'white',
            p: '4px',
            mb: -1,
            mx: -1,
            wordBreak: 'keep-all',
            fontWeight: 'bold',
            fontStyle: 'normal',
            fontSize: 0,
            display: 'grid',
            alignItems: 'center',
            gridGap: '4px',
            gridTemplateColumns: 'max-content 1fr',
          }}
        >
          <Icons.Info />
          {R.gt(props.minCents, amountCents) &&
            translate('payment-method.min-amount', {amount: minAmount})}
          {R.lt(props.maxCents, amountCents) &&
            translate('payment-method.max-amount', {amount: maxAmount})}
          {props.feePercent > 0 &&
            !R.lt(props.maxCents, amountCents) &&
            !R.gt(props.minCents, amountCents) &&
            translate('payment-method.fee', {
              amount: feeAmount,
              percentage: props.feePercent,
            })}
        </ThemeUi.Box>
      )}
    </ThemeUi.Box>
  )
}

PaymentMethod.propTypes = {
  feePercent: PropTypes.number,
  id: PropTypes.string.isRequired,
  lastSuccess: PropTypes.string,
  maskedAccount: PropTypes.string,
  maxCents: PropTypes.number.isRequired,
  minCents: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  selected: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

Herz.I18n.Loader.preload(
  [
    'payment-method.min-amount',
    'payment-method.max-amount',
    'payment-method.fee',
    'payment-method.last-used',
    'payment-method.japan-credit-card-ftd',
  ],
  PaymentMethod
)
