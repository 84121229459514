import * as Configuration from './configuration'
import * as Constants from './constants'
import * as Data from './data'
import * as Forms from '@rushplay/forms'
import * as Payments from './payments'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as Reselect from 'reselect'
import * as SignUp from './sign-up'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

const STATE_BY_TOKEN_ENDPOINT = {
  staging: 'https://abonea-176213.appspot.com/api/session.state_by_token',
  production: 'https://abonea-249014.appspot.com/api/session.state_by_token',
}

/**
 * Selector for data to process with the transaction
 * @param {Object} state Redux state
 * @returns {Object} configurations for brite
 */
const getBriteData = Reselect.createStructuredSelector({
  apiUrl: (state) => Configuration.getBriteConfig(state).apiUrl,
  environment: Configuration.getEnvironment,
  depositOfferId: Configuration.getOfferId,
  affiliateClickId: Data.getAffiliateClickId,
  brandKey: Configuration.getBrand,
  btag: Data.getNetrefererBtag,
  clientType: Configuration.getClientType,
  countryCode: Configuration.getCountryCode,
  seonSession: Configuration.getSeonSession,
  language: Configuration.getLanguage,
  sessionToken: Configuration.getToken,
  utmCampaign: Data.getUtmCampaign,
  utmMedium: Data.getUtmMedium,
  utmSource: Data.getUtmSource,
})

/**
 * Component to handle Brite transactions
 * @component BriteProcessing
 * @param {Object} props Components props
 * @param {func} props.onStepChange callback to change payment step
 * @returns {ReactNode} Iframe with brite loaded
 */
export function BriteProcessing(props) {
  const dispatch = ReactRedux.useDispatch()
  const briteData = ReactRedux.useSelector(getBriteData)
  const {transactionType} = Payments.useTransactionContext()
  const amountCents = Forms.useField('#/properties/amount', {noRegister: true})
    .value
  const origin = ReactRedux.useSelector(Configuration.getOrigin)

  const brite = SignUp.useBrite({
    ...briteData,
    amountCents,
    transactionType,
    onFailure: (res) => {
      dispatch(Data.updateForwardingData({errors: res.errors}))
      props.onStepChange(Constants.TransactionStep.Failure)
    },
    onSuccess: (sessionToken) => {
      dispatch(Data.updateForwardingData({sessionToken}))
      props.onStepChange(Constants.TransactionStep.Success)
    },
  })
  const {token} = brite

  React.useEffect(() => {
    if (token && transactionType === 'deposit') {
      const providerUrl = STATE_BY_TOKEN_ENDPOINT[briteData.environment]
      const url = `${origin}/events/transaction?url=${providerUrl}&token=${token}`
      dispatch(Data.transactionStart({url}))
    }
  }, [briteData.environment, dispatch, origin, token, transactionType])

  return (
    <ThemeUi.Flex
      sx={{
        'alignItems': 'center',
        'justifyContent': 'center',
        'flexDirection': 'column',
        'height': '100%',
        '#brite-payment-system': {
          height: '100%',
          width: '100%',
          maxWidth: '600px',
        },
      }}
    >
      {!brite.isAwaitingInput && <ThemeUi.Spinner sx={{mt: 2}} />}
      <brite.Iframe />
    </ThemeUi.Flex>
  )
}

BriteProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}
