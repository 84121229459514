import * as Configuration from './configuration'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Transforms inputs into a imgproxy request url
 * @param {string} imgproxyUrl The base url where imgproxy is hosted
 * @param {string} source The url of the source-image to be processed
 * @param {number} [dpr = 1] Devices' pixel ratio
 * @param {('jpg' | 'png' | 'webp')} [extension = ''] Used to convert image into this type
 * @param {number} [resizingHeight = 0] The height to resize source into
 * @param {string} [resizingType = 'fill'] How to recalulate the resizing
 * @param {number} [resizingWidth = 0] The width to resize source into
 * @returns {string} Imgproxy request url
 */
function toImageUrl(
  imgproxyUrl = '',
  source = '',
  {
    dpr = 1,
    extension = '',
    resizingHeight = 0,
    resizingType = 'fill',
    resizingWidth = 0,
  } = {}
) {
  const processingOptions = {
    resize: [resizingType, resizingWidth, resizingHeight, 0],
    dpr: [dpr],
    crop: [0, 0],
    gravity: ['ce', 0, 0],
  }

  const processingKeys = R.keys(processingOptions)

  const formattedProcessingOptions = R.reduce(
    (options, processKey) => {
      const option = processingOptions[processKey]

      if (R.not(R.any(R.isNil, option))) {
        return (options +=
          R.join(':', R.prepend(processKey, option)) +
          (R.indexOf(processKey, processingKeys) !==
          R.length(processingKeys) - 1
            ? '/'
            : ''))
      }

      return options
    },
    '',
    processingKeys
  )

  const url = `${imgproxyUrl}/insecure/${formattedProcessingOptions}/plain${source}${
    R.length(extension) && R.not(R.includes(extension, source))
      ? `@${extension}`
      : ''
  }`

  return url
}

/**
 * Image component for resizing and converting remote images.
 * The component is using imgproxy API, see [imgproxy documenation]{@link https://github.com/imgproxy/imgproxy/blob/master/docs/generating_the_url_advanced.md} for more information
 * @param {Object} props
 * @param {string} props.alt text to show when image is unavailable
 * @param {('jpg' | 'png')} [props.extension = ''] if you want to force an extention
 * @param {number=} props.resizingHeight height to resize for
 * @param {number=} props.resizingWidth width to resize for
 * @param {('fill' | 'fit' | 'auto')} [props.resizingType = 'fill'] how to calculate the resize
 * @param {string=} props.source the path to the source-file
 * @param {Object=} props.sx inline-style props with theme access
 * @returns {ReactNode} Image component with performant image-source
 */
export function Image(props) {
  const imgproxyUrl = ReactRedux.useSelector(Configuration.getImgProxyUrl)
  const dpr = ReactRedux.useSelector(Configuration.getDevicePixelRatio)

  const options = {
    dpr,
    extension: props.extension,
    resizingHeight: props.resizingHeight,
    resizingWidth: props.resizingWidth,
  }

  const webpOptions = R.assoc('extension', 'webp', options)

  return (
    <picture style={{display: 'contents'}}>
      <source
        type="image/webp"
        srcSet={toImageUrl(imgproxyUrl, props.source, webpOptions)}
      />
      <ThemeUi.Image
        sx={props.sx}
        alt={props.alt}
        src={toImageUrl(imgproxyUrl, props.source, options)}
      />
    </picture>
  )
}

Image.propTypes = {
  alt: PropTypes.string,
  extension: PropTypes.oneOf(['jpg', 'png']),
  resizingHeight: PropTypes.number,
  resizingType: PropTypes.oneOf(['fill', 'fit', 'auto']),
  resizingWidth: PropTypes.number,
  source: PropTypes.string,
  sx: PropTypes.object,
}
