import * as Forms from '@rushplay/forms'
import * as React from 'react'

import PropTypes from 'prop-types'

/**
 * Component to be used for values that are prefilled and we want
 * to autosubmit or otherwise not show but still have the data inside Forms.
 * @component HiddenInputField
 * @param {Object} props Components props
 * @param {string} props.initialValue initial value of component
 * @param {string} props.scope scope inside dataschema of parent Form
 * @returns {ReactNode} hidden input with it's value merged into Form state
 */
export function HiddenInputField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })

  return <input type="hidden" {...field} />
}

HiddenInputField.propTypes = {
  initialValue: PropTypes.string.isRequired,
  scope: PropTypes.string.isRequired,
}
