import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {FieldResponse} from './field-response'
import PropTypes from 'prop-types'
import {getNormalizedTranslationKey} from './get-normalized-translation-key'

/**
 * Component that extends native Select with field-updating capabilities
 * @component SelectField
 * @param {Object} props Components props
 * @param {string=} props.autoComplete @see {@link https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete}
 * @param {boolean=} props.autoFocus if form-control should be focused on render
 * @param {boolean=} props.disabled if control is enabled or not
 * @param {string|number=} props.initialValue intial value of control
 * @param {Object[]=} props.options options to select from
 * @param {boolean=} props.readOnly if control is editable or not
 * @param {string} props.scope scope inside dataschema of parent Form
 * @returns {ReactNode} Select that updates Form state via Field
 */
export function SelectField(props) {
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
  })
  const paymentMethodId = Forms.useField('#/properties/provider', {
    noRegister: true,
  }).value

  const labelKey = getNormalizedTranslationKey(field.label, paymentMethodId)
  const placeholderKey = getNormalizedTranslationKey(
    field.placeholder,
    paymentMethodId
  )
  const optionLabels = props.options ? R.pluck('label', props.options) : []
  const extraKeysToPreload = props.extraKeys || []

  const translate = Herz.I18n.useTranslate(() => [
    labelKey,
    placeholderKey,
    ...optionLabels,
    ...extraKeysToPreload,
  ])

  const value = field.value && field.value.toString()

  return (
    <ThemeUi.Grid>
      <ThemeUi.Field
        as={ThemeUi.Select}
        label={translate(labelKey)}
        autoComplete={props.autoComplete}
        autoFocus={props.autoFocus}
        disabled={props.disabled}
        readOnly={props.readOnly}
        name={field.name}
        placeholder={translate(placeholderKey)}
        value={value}
        onChange={field.onChange}
        onBlur={field.onBlur}
      >
        {props.options &&
          R.map(
            (option) => (
              <option key={option.value} value={option.value}>
                {translate(option.label)}
              </option>
            ),
            props.options
          )}
      </ThemeUi.Field>
      <FieldResponse scope={props.scope} />
    </ThemeUi.Grid>
  )
}

SelectField.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  extraKeys: PropTypes.array,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  scope: PropTypes.string,
}
