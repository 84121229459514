import * as Configuration from '../configuration'
import * as Constants from '../constants'
import * as R from 'ramda'
import * as ReactRedux from 'react-redux'

import PropTypes from 'prop-types'

/**
 * Format number as a currency string
 * @param {number} amountCents
 * @param {Object} settings Settings needed for formatting
 * @param {string} settings.locale BCP 47 language tag (usually shortest ISO 639 code sometimes followed by extended language subtags (such as region))
 * @param {string} settings.currency Currency code according to ISO 4217
 * @param {string} [settings.maximumFractionDigits = 2] Maximum digits to show after decimal marker
 * @param {string} [settings.minimumFractionDigits = 2] Minimum digits to show after decimal marker
 *
 * @example
 * // returns '€12.34'
 * toAmount(1234, { locale: 'en-DE', currency: 'EUR' })
 *
 * @returns {string} Amount formatted as a currencystring
 */
function toAmount(amountCents, settings) {
  if (!settings.locale) {
    // eslint-disable-next-line no-console
    console.warn('locale is needed')
    return null
  }

  if (!settings.currency) {
    // eslint-disable-next-line no-console
    console.warn('currency is needed')
    return null
  }

  const isCustomCurrency = R.path(
    [settings.currency, 'customCurrency'],
    Constants.currencies
  )
  const symbol = R.pathOr(
    settings.currency,
    [settings.currency, 'customCurrency'],
    Constants.currencies
  )
  const converterValue = R.pathOr(
    100,
    [settings.currency, 'ratio'],
    Constants.currencies
  )
  const amount = amountCents / converterValue

  if (Number.isNaN(amount)) {
    // eslint-disable-next-line no-console
    console.warn('amountCents needs to be a number')
    return null
  }

  if (isCustomCurrency) {
    amount.toLocaleString(settings.locale, {
      minimumFractionDigits: settings.minimumFractionDigits || '2',
      maximumFractionDigits: settings.maximumFractionDigits || '2',
      style: 'decimal',
    })
    return ''.concat(amount, ' ').concat(symbol)
  }
  return amount.toLocaleString(settings.locale, {
    style: 'currency',
    currency: settings.currency,
    minimumFractionDigits: settings.minimumFractionDigits || '2',
    maximumFractionDigits: settings.maximumFractionDigits || '2',
  })
}

// Custom hook that prefills data for toAmount function
/**
 * Hook that gathers basic data for currency transformations itself
 * @param {Object} props
 * @param {number} props.amountCents
 * @param {number=} props.maximumFractionDigits control over maximum fraction digits
 * @param {number=} props.minimumFractionDigits control over minimum fraction digits
 * @returns {string} Amountcents transformed into locale currency string
 */
export function useToAmount(props) {
  const currency = ReactRedux.useSelector(Configuration.getCurrency)
  const locale = ReactRedux.useSelector(Configuration.getLocale)

  return toAmount(props.amountCents, {
    currency,
    locale,
    maximumFractionDigits: props.maximumFractionDigits,
    minimumFractionDigits: props.minimumFractionDigits,
  })
}

useToAmount.propTypes = {
  amountCents: PropTypes.number,
  maximumFractionDigits: PropTypes.number,
  minimumFractionDigits: PropTypes.number,
}
