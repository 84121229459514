import * as Data from './data'
import * as Icons from './icons'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

/**
 * Component to render when user chose to cancel their transaction
 * @component Cancel
 * @returns {ReactNode}
 */
export function Cancel() {
  const dispatch = ReactRedux.useDispatch()

  React.useEffect(() => {
    dispatch(Data.transactionCancel())
  }, [dispatch])

  return (
    <ThemeUi.Box
      sx={{
        display: 'flex',
        flexGrow: '1',
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        fontSize: '120px',
        color: 'muted',
      }}
    >
      <Icons.Cancel />
    </ThemeUi.Box>
  )
}
