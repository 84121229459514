import * as Herz from '@rushplay/herz'
import * as Icons from './icons'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

/**
 * Component to render when a transaction is pending
 * @component Pending
 * @returns {ReactNode}
 */
export function Pending() {
  const translate = Herz.I18n.useTranslate()

  return (
    <ThemeUi.Grid
      sx={{
        pt: 2,
        justifyContent: 'center',
        textAlign: 'center',
        fontSize: 1,
        color: 'text',
        gridGap: 1,
      }}
    >
      <ThemeUi.Box
        sx={{
          color: 'info',
          fontSize: '120px',
          pb: 2,
        }}
      >
        <Icons.Info />
      </ThemeUi.Box>
      <ThemeUi.Text>{translate('transaction.pending')}</ThemeUi.Text>
    </ThemeUi.Grid>
  )
}

Herz.I18n.Loader.preload(['transaction.pending'], Pending)
