import * as Logger from './logger'

import AbortController from 'node-abort-controller'
import crossFetch from 'cross-fetch'
import {v4 as generateUuid} from 'uuid'

/**
 * Component to generalise fetch errors
 * @component FetchError
 * @param {string} message error message
 * @param {number} code Error code
 * @returns {ReactNode} Error
 */
class FetchError extends Error {
  constructor(message, code) {
    super(message)
    this.name = 'FetchError'
    this.statusCode = code
  }
}

/**
 * Helper function to get response into readable format
 * @param {Object} response
 * @returns {Object} normalized response body
 */
async function body(response) {
  const contentType = response.headers.get('Content-Type')

  if (contentType.startsWith('application/json')) {
    return response.json()
  }

  return response.text()
}

// Basic http.fetch setup
export async function fetch(resource, options) {
  const {timeout = 15000} = options
  const controller = new AbortController()
  const id = setTimeout(() => controller.abort(), timeout)
  const uuid = generateUuid()

  Logger.request.debug(
    {
      resource,
      options,
      uuid,
    },
    'request started'
  )

  const response = await crossFetch(resource, {
    ...options,
    signal: controller.signal,
  })

  clearTimeout(id)

  if (response.status < 400) {
    Logger.request.debug(
      {
        body: await body(response.clone()),
        status: response.status,
        uuid,
      },
      'request completed'
    )
  } else {
    const body = await body(response.clone())

    Logger.request.error(
      {
        body,
        status: response.status,
        uuid,
      },
      'request failed'
    )

    throw new FetchError(body, response.status)
  }

  return response
}
