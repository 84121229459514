// Logger module
//
// This module is created log application events of various importance. It uses
// Pino as logger (https://getpino.io) as it’s claimed to be small and fast.

// Logging guidelines
//
// - Keep messages concise.
// - Message must start with lowercase letter
// - If message describes start of the process, append ellipsis.
//   Example: "dismantling shenanigans..."
// - For most long running processes there must be “completed” log message
//   Example: "dismantling shenanigans completed"

import createHttpLogger from 'pino-http'
import createLogger from 'pino'

function getBindings(bindings) {
  return {
    ...bindings,
    requestId:
      process.env.BUILD_TARGET === 'client' ? window.__REQUEST_ID__ : null,
    runtime: process.env.BUILD_TARGET,
  }
}

function getLogLevel() {
  if (!process.env.LOG_LEVEL) {
    return 'info'
  }

  return process.env.LOG_LEVEL
}

// Main logger instance
//
// It shall be used to create child loggers thus not exported. Child loggers
// are used to distinguish where occured event comes from. See descriptions of
// specific loggers for more info.
const logger = createLogger({
  browser: {
    asObject: true,
    transmit: {
      level: 'info',
      send(level, logEvent) {
        navigator.sendBeacon('/log', JSON.stringify(logEvent))
      },
    },
  },
  level: getLogLevel(),
  name: 'payer',
  prettyPrint: process.env.NODE_ENV !== 'production',
})

// Root logger
//
// Shall be used to report events which are either application-wide or hard to
// categorize. Otherwise use loggers for specific components.
export const root = logger.child(getBindings({component: 'ROOT'}))

// Incoming HTTP request logger
//
// Specialized logger for incoming HTTP requests and their responses.
// Must be called with Request and Response objects in NodeJS request handler.
// More info: https://github.com/pinojs/pino-http
export const http = createHttpLogger({
  component: 'HTTP',
  logger,
})

// Outgoing HTTP request logger
export const request = logger.child(getBindings({component: 'REQUEST'}))

// React renderer logger
export const react = logger.child(getBindings({component: 'REACT'}))

// Server logger
export const server = logger.child(getBindings({component: 'SERVER'}))
