import * as Forms from '@rushplay/forms'
import * as Herz from '@rushplay/herz'
import * as R from 'ramda'
import * as React from 'react'
import * as ThemeUi from 'theme-ui'

import {Checkbox} from './checkbox'
import {FieldResponse} from './field-response'
import PropTypes from 'prop-types'
import {getFieldNormalizer} from './get-field-normalizer'
import {getFieldParser} from './get-field-parser'
import {getNormalizedTranslationKey} from './get-normalized-translation-key'

/**
 * Component that extends native Checkboxes with field-updating capabilities
 * @component MultipleSelectField
 * @param {Object} props Components props
 * @param {boolean=} props.disabled if control is enabled or not
 * @param {string=} props.format format to use for normalizer/parser
 * @param {string|number=} props.initialValue intial value of control
 * @param {func=} props.normalize function to use for normalisation of field value
 * @param {Object[]=} props.options options to select from
 * @param {func=} props.parse function to use for parsing of field value
 * @param {string} props.scope scope inside dataschema of parent Form
 * @returns {ReactNode} Checkboxes that updates Form state via Field
 */
export function MultipleSelectField(props) {
  const [options, setOptions] = React.useState([])
  const field = Forms.useField(props.scope, {
    initialValue: props.initialValue,
    normalize: getFieldNormalizer(props.format, props.normalize),
    parse: getFieldParser(props.format, props.parse),
  })
  const paymentMethodId = Forms.useField('#/properties/provider', {
    noRegister: true,
  }).value
  const labelKey = getNormalizedTranslationKey(field.label, paymentMethodId)

  const translate = Herz.I18n.useTranslate(() => [labelKey])

  function handleChange(key, value) {
    // checkbox was true
    if (value && R.not(R.includes(key, options))) {
      const nextOptions = R.append(key, options)
      setOptions(nextOptions)
      return field.onChangeValue(nextOptions)
    }
    // checkbox was false
    const nextOptions = R.without(key, options)
    setOptions(nextOptions)
    return field.onChangeValue(nextOptions)
  }

  return (
    <ThemeUi.Grid gridGap={0}>
      <ThemeUi.Label>{translate(labelKey)}</ThemeUi.Label>
      {R.map(
        (item) => (
          <Checkbox
            key={item.value}
            disabled={R.includes(item.key, props.disabled)}
            checked={R.includes(item.value, options)}
            value={R.includes(item.value, options)}
            onChange={(value) => handleChange(item.value, value)}
            label={getNormalizedTranslationKey(item.label, paymentMethodId)}
          />
        ),
        props.options
      )}
      <FieldResponse scope={props.scope} />
    </ThemeUi.Grid>
  )
}

MultipleSelectField.propTypes = {
  disabled: PropTypes.array,
  format: PropTypes.string,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  normalize: PropTypes.func,
  options: PropTypes.array,
  parse: PropTypes.func,
  scope: PropTypes.string.isRequired,
}
