import * as Constants from './constants'
import * as Herz from '@rushplay/herz'
import * as Payments from './payments'
import * as R from 'ramda'
import * as React from 'react'
import * as ReactRedux from 'react-redux'
import * as ThemeUi from 'theme-ui'

import PropTypes from 'prop-types'

/**
 * Component to handle transactions via TransferWorld
 * @component TransferWorldProcessing
 * @returns {ReactNode} Iframe or redirection link
 */
export function TransferWorldProcessing(props) {
  const transaction = Payments.useTransactionContext()

  const redirectUrl = ReactRedux.useSelector((state) =>
    R.path(['activeTransaction', 'redirectOutput', 'url'], state.payments)
  )

  const transactionState = ReactRedux.useSelector((state) =>
    Payments.getTransactionState(state.payments)
  )

  const translate = Herz.I18n.useTranslate()

  const isAwaitingInput = R.includes(transactionState, [
    Payments.TransactionState.WAITING_INPUT,
    Payments.TransactionState.PROCESSING,
  ])

  React.useEffect(() => {
    function messageReceive(event) {
      if (event.key == 'pendingTransferWorldTransactionMessage') {
        props.onStepChange(Constants.TransactionStep.Pending)

        localStorage.removeItem('pendingTransferWorldTransactionMessage')
      }
    }

    window.addEventListener('storage', messageReceive)

    return () => window.removeEventListener('storage', messageReceive)
  }, [])

  if (
    R.not(transactionState) ||
    (isAwaitingInput && transaction.redirectParams == null)
  ) {
    return (
      <ThemeUi.Flex
        sx={{
          pt: 2,
          justifyContent: 'center',
        }}
      >
        <ThemeUi.Grid
          sx={{
            fontSize: 1,
            gridGap: 1,
            lineHeight: 'body',
            textAlign: 'center',
            flexGrow: 1,
          }}
        >
          <ThemeUi.Box
            sx={{
              pb: 2,
              fontSize: 5,
            }}
          >
            <ThemeUi.Spinner />
          </ThemeUi.Box>
          {translate('transfer-world-processing.redirection-info.title')}
          <ThemeUi.Button
            as="a"
            rel="noopener noreferrer"
            target="_blank"
            sx={{mt: 1}}
            href={redirectUrl}
          >
            {translate('transfer-world-processing.redirection-info.link')}
          </ThemeUi.Button>
        </ThemeUi.Grid>
      </ThemeUi.Flex>
    )
  }

  if (isAwaitingInput && transaction.redirectParams != null) {
    return (
      <ThemeUi.Box
        sx={{
          'width': '100%',
          'height': '100%',
          '> iframe': {
            border: 'none',
            margin: '0px',
          },
        }}
      >
        <Payments.ActiveTransaction />
      </ThemeUi.Box>
    )
  }

  return null
}

TransferWorldProcessing.propTypes = {
  onStepChange: PropTypes.func.isRequired,
}

Herz.I18n.Loader.preload(
  [
    'transfer-world-processing.redirection-info.title',
    'transfer-world-processing.redirection-info.link',
  ],
  TransferWorldProcessing
)
