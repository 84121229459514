import * as React from 'react'
import * as ReactSpring from 'react-spring'

import PropTypes from 'prop-types'

/**
 * Component to wrap child for a fade in/out animation
 * @component FadeInOut
 * @param {Object} props Components props
 * @returns {ReactNode}
 */
export function FadeInOut(props) {
  const transitions = ReactSpring.useTransition(props.children, null, {
    from: {opacity: 0, flex: '1'},
    enter: {opacity: 1},
    leave: {opacity: 0},
  })

  return transitions.map((transition) => (
    <ReactSpring.animated.div key={transition.key} style={transition.props}>
      {transition.item}
    </ReactSpring.animated.div>
  ))
}

FadeInOut.propTypes = {
  children: PropTypes.node,
}
