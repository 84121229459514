import * as Forms from '@rushplay/forms'
import * as React from 'react'

import PropTypes from 'prop-types'
import {SelectField} from './select-field'

// source: https://developers.cryptopay.me/guides/currencies/currencies
const AVAILABLE_NETWORKS = {
  BTC: ['bitcoin', 'bnb_smart_chain'],
  LTC: ['litecoin', 'bnb_smart_chain'],
  ETH: ['ethereum', 'bnb_smart_chain'],
  XRP: ['ripple', 'bnb_smart_chain'],
  XLM: ['stellar', 'bnb_smart_chain'],
  BCH: ['bitcoin_cash', 'bnb_smart_chain'],
  ADA: ['cardano', 'bnb_smart_chain'],
  SOL: ['solana', 'bnb_smart_chain'],
  SHIB: ['ethereum', 'bnb_smart_chain'],
  TRX: ['tron', 'bnb_smart_chain'],
  DOGE: ['dogecoin', 'bnb_smart_chain'],
  BNB: ['bnb_smart_chain'],
  USDT: ['ethereum', 'tron', 'bnb_smart_chain', 'solana'],
  USDC: ['ethereum', 'bnb_smart_chain', 'solana'],
  DAI: ['ethereum', 'bnb_smart_chain'],
}
export function CryptoNetworkSelector() {
  const paymentMethodId = Forms.useField('#/properties/provider', {
    noRegister: true,
  }).value
  const currencyField =
    Forms.useField(
      `#/definitions/${paymentMethodId}/properties/cryptoCurrency`,
      {
        noRegister: true,
      }
    ).value || 'USDT'

  const networks = AVAILABLE_NETWORKS[currencyField]

  const options = networks.map((network) => ({
    value: network,
    label: `transaction.network.${network}`,
  }))

  const networkUpdate = Forms.useField(
    `#/definitions/${paymentMethodId}/properties/network`,
    {
      noRegister: true,
    }
  ).onChangeValue

  const initialValue = options[0].value

  React.useEffect(() => {
    networkUpdate(initialValue)
  }, [initialValue, networkUpdate])

  return (
    <SelectField
      autoFocus
      // If all the keys are not preloaded from the start, the SelectField component crashes
      extraKeys={[
        'transaction.network.bitcoin',
        'transaction.network.litecoin',
        'transaction.network.ethereum',
        'transaction.network.ripple',
        'transaction.network.stellar',
        'transaction.network.bitcoin_cash',
        'transaction.network.cardano',
        'transaction.network.solana',
        'transaction.network.tron',
        'transaction.network.dogecoin',
        'transaction.network.bnb_smart_chain',
        'transaction.network.ethereum',
        'transaction.network.tron',
        'transaction.network.bnb_smart_chain',
        'transaction.network.solana',
      ]}
      initialValue={initialValue}
      options={options}
      scope={`#/definitions/${paymentMethodId}/properties/network`}
    />
  )
}

CryptoNetworkSelector.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
  initialValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  options: PropTypes.array,
  readOnly: PropTypes.bool,
  scope: PropTypes.string,
}
