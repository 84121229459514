import * as React from 'react'

/**
 * Hook implementation of lifecycle method `componentDidUpdate`
 * @param {*} value
 * returns {*} Value of `value` before update
 */
export function usePrev(value) {
  const ref = React.useRef(value)
  React.useEffect(() => {
    if (value !== ref.current) {
      ref.current = value
    }
  }, [value])
  return ref.current
}
