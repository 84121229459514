import * as Payments from '../payments'
import * as React from 'react'

import {SelectField} from './select-field'
import {usePaymentMethod} from '../payments/components/payment-method/use-payment-method'

export function VevopaySelector() {
  const transaction = Payments.useTransactionContext()
  const capitalizedTransactionType =
    transaction?.transactionType?.charAt(0)?.toUpperCase() +
    transaction?.transactionType?.slice(1)

  const paymnetMethod = usePaymentMethod(
    `VevopayVevopay${capitalizedTransactionType}`
  )

  const methodsList = paymnetMethod?.subPaymentMethods || []
  const options = methodsList.map((method) => ({
    label: `transaction.payment-method.${method}`,
    value: method,
  }))

  return (
    <>
      <SelectField
        autoFocus
        options={options}
        initialValue={options[0]?.value}
        scope={`#/definitions/VevopayVevopay${capitalizedTransactionType}/properties/paymentMethod`}
      />
    </>
  )
}
