import * as R from 'ramda'

import kebabCase from 'lodash.kebabcase'

/**
 * @param {string} translationKey - Translation key either the fields placeholder or label.
 * @param {string} paymentMethodId - Payment method id.
 * @returns {string} normalized translation key or key as is.
 */
export function getNormalizedTranslationKey(translationKey, paymentMethodId) {
  if (paymentMethodId) {
    // Inserts payment method id to make key unqiue
    return R.join(
      '.',
      R.insert(1, kebabCase(paymentMethodId), R.split('.', translationKey))
    )
  } else {
    return translationKey
  }
}
